import { Timer3dEnv } from './timer3d-env';
import minuteToModelRotation from './minute-to-model-rotation';
import { getMinutes } from '@entities/timer';
import { Action, AnimationScheduler } from './animation-scheduler';

enum InitialAnimation {
	NOT_STARTED,
	STARTED,
	FINISHED,
}

const TIMER_INITIAL_Y_POSITION = -0.14;

function getInterstitialPosition(currentTime: string): number {
	const [minutes, seconds] = currentTime.split(':').map(Number);
	const currentMinutePosition = minuteToModelRotation[minutes];
	const nextMinutePosition = minuteToModelRotation[minutes + 1];

	return (
		currentMinutePosition +
		((nextMinutePosition - currentMinutePosition) / 60) * seconds
	);
}

export class TimerAnimator {
	private scheduler: AnimationScheduler;
	private initialAnimationStep = InitialAnimation.NOT_STARTED;
	private timer3dScene: Timer3dEnv;

	constructor(timer3dScene: Timer3dEnv) {
		this.timer3dScene = timer3dScene;
		this.scheduler = new AnimationScheduler(this.timer3dScene);
		timer3dScene.entireTimer.position.y = TIMER_INITIAL_Y_POSITION;
	}

	playInitialAnimation() {
		if (this.initialAnimationStep === InitialAnimation.NOT_STARTED) {
			this.scheduler.addAction({
				type: 'move-in',
				handler: ({ clock }) => {
					const initialPosition = TIMER_INITIAL_Y_POSITION;
					const needed = 0;
					const nextValue =
						initialPosition +
						((needed - initialPosition) * (clock.getElapsedTime() * 1000)) /
							1000;

					if (nextValue > needed) {
						this.timer3dScene.entireTimer.position.y = needed;
						return true;
					}

					this.timer3dScene.entireTimer.position.y = nextValue;
					return false;
				},
				onDone: () => (this.initialAnimationStep = InitialAnimation.FINISHED),
				timeToExecute: 1500,
			});
			this.initialAnimationStep = InitialAnimation.STARTED;
		}
	}

	playRotationAnimation(isRunning: boolean, windTime: string) {
		const minutes = getMinutes(windTime);
		const needed = isRunning
			? getInterstitialPosition(String(windTime))
			: minuteToModelRotation[minutes];
		const initialPosition = this.timer3dScene.getTopHemisphereRotation();

		this.scheduler.replaceAction({
			timeToExecute: 200,
			customData: { initialPosition, needed },
			handler: (action: Action) => {
				const elapsedTime = action.clock!.getElapsedTime() * 1000;
				const current = this.timer3dScene.getTopHemisphereRotation();
				const shouldDecrease = current > needed;

				const nextValue = shouldDecrease
					? initialPosition -
						(initialPosition - needed) * (elapsedTime / action.timeToExecute)
					: initialPosition +
						(needed - initialPosition) * (elapsedTime / action.timeToExecute);

				if (shouldDecrease && nextValue < needed) {
					this.timer3dScene.rotateTopHemisphere(needed);
					return true;
				} else if (!shouldDecrease && nextValue > needed) {
					this.timer3dScene.rotateTopHemisphere(needed);
					return true;
				}
				this.timer3dScene.rotateTopHemisphere(nextValue);
				return false;
			},
			type: 'rotation',
		});
	}
}

interface TimerFinishedData {
	cycleType: string;
	theme: string;
	volumeLevel: number;
	timeSet: number;
	timeStopped: number;
	numberToday: number;
	hasAssociatedTask?: boolean;
}

declare global {
	interface Window {
		gtag: (arg0: unknown, arg1: unknown, arg2: unknown) => void;
	}
}

export function sendTimerFinishedEvent(data: TimerFinishedData) {
	if (window.gtag) {
		window.gtag('event', 'timer_finished', data);
	}
}

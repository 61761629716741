import { useSelector } from 'react-redux';
import {
	selectAutostart,
	selectLongBreakEvery,
	selectTheme,
	selectVolumeLevel,
} from '@entities/setting/model/store.ts';

// ---

export default function useSettings() {
	const autostart = useSelector(selectAutostart);
	const longBreakEvery = useSelector(selectLongBreakEvery);
	const volumeLevel = useSelector(selectVolumeLevel);
	const theme = useSelector(selectTheme);

	return {
		autostart,
		longBreakEvery,
		volumeLevel,
		theme,
	};
}

import { Theme, useCurrentTheme } from '@entities/setting';

export default function Logo() {
	const currentTheme = useCurrentTheme();
	return currentTheme === Theme.DARK ? (
		<>
			<img
				src="/images/colorful-full-logo.svg"
				alt="Logo"
				className="hidden h-12 sm:block"
			/>
			<img
				src="/images/colorful-icon-logo.svg"
				alt="Logo"
				className="h-12 sm:hidden"
			/>
		</>
	) : (
		<>
			<img
				src="/images/black-full-logo.svg"
				alt="Logo"
				className="hidden h-12 sm:block"
			/>
			<img
				src="/images/black-icon-logo.svg"
				alt="Logo"
				className="h-12 sm:hidden"
			/>
		</>
	);
}

import { useEffect, useRef } from 'react';
import { PressEvent } from 'react-aria-components';
import { GLTF, GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { useSelector } from 'react-redux';

import { useHold } from '@shared/lib';
import {
	selectCurrentCycle,
	getMinutes,
	MAX_TIMER_VALUE,
	MIN_TIMER_VALUE,
} from '@entities/timer';
import { ChevronLeft, Button, ChevronRight } from '@shared/ui';

import minuteToModelRotation from './minute-to-model-rotation.ts';
import { I18nMessage } from '@shared/i18n';
import { useThemeAttr } from '@entities/setting';
import { Timer3dEnv } from '@features/display-time-and-set-timer/ui/Timer3D/timer3d-env';
import { TimerAnimator } from '@features/display-time-and-set-timer/ui/Timer3D/timer-animator';

// ---

const USE_HOLD_OPTIONS = {
	delay: 400,
	decrement: 100,
	minimumDelay: 150,
};

const timer3dUrl = new URL(
	'@shared/assets/objects/kitchen_timer.glb',
	import.meta.url
);
const assetLoader = new GLTFLoader();

let animator: TimerAnimator;
let timer3dEnv: Timer3dEnv;

interface Timer3dProps {
	windTime: string;
	onWind: (time: number) => void;
	disableWinding: boolean;
	isRunning: boolean;
}

function loadModel(onLoad: (gltf: GLTF) => void) {
	assetLoader.load(timer3dUrl.href, onLoad, undefined, (error: unknown) => {
		console.log(error);
	});
}

export default function Timer3D({
	windTime,
	onWind,
	disableWinding,
	isRunning,
}: Timer3dProps) {
	const currentCycle = useSelector(selectCurrentCycle);
	const buttonWrapperRef = useRef<HTMLDivElement>(null);
	const minutesRef = useRef(getMinutes(windTime));
	const minutes = getMinutes(windTime);

	const { handleMouseDown: lessMouseDown, handleMouseUp: lessMouseUp } =
		useHold<PressEvent>(() => {
			if (minutesRef.current === MIN_TIMER_VALUE) {
				return;
			}
			onWind(minutesRef.current - 1);
		}, USE_HOLD_OPTIONS);

	const { handleMouseDown: moreMouseDown, handleMouseUp: moreMouseUp } =
		useHold<PressEvent>(() => {
			if (minutesRef.current === MAX_TIMER_VALUE) {
				return;
			}
			onWind(minutesRef.current + 1);
		}, USE_HOLD_OPTIONS);

	useEffect(() => {
		minutesRef.current = getMinutes(windTime);
	}, [windTime]);

	useEffect(() => {
		loadModel((gltf: GLTF) => {
			timer3dEnv = new Timer3dEnv(
				gltf.scene,
				document.querySelector('#bg') as HTMLCanvasElement
			);
			timer3dEnv.rotateTopHemisphere(minuteToModelRotation[minutes]);
			animator = new TimerAnimator(timer3dEnv);
			animator.playInitialAnimation();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (animator) {
			animator.playRotationAnimation(isRunning, windTime);
		}
	}, [windTime, isRunning, currentCycle]);

	useThemeAttr(buttonWrapperRef.current, 'dark');

	return (
		<div className="group/timer relative grow">
			<canvas
				className="h-full w-full rounded-full border-2 border-baseStrong-700 bg-baseStrong-800"
				id="bg"
			></canvas>
			{!disableWinding && (
				<div
					className="translate absolute left-0 top-[50%] flex w-full translate-y-[-50%] transform justify-between text-3xl text-white opacity-0 duration-500 focus-within:opacity-100 group-hover/timer:opacity-100"
					ref={buttonWrapperRef}
				>
					<Button
						round
						type="nostyles"
						textSizeOverride="text-5xl"
						size="lg"
						onMouseDown={lessMouseDown}
						onMouseUp={lessMouseUp}
					>
						<span className="sr-only">
							<I18nMessage
								id={'timer.changeTimeTo'}
								values={{ time: minutes - 1 }}
							/>
						</span>
						<ChevronLeft />
					</Button>
					<Button
						round
						type="nostyles"
						textSizeOverride="text-5xl"
						size="lg"
						onMouseDown={moreMouseDown}
						onMouseUp={moreMouseUp}
					>
						<span className="sr-only">
							<I18nMessage
								id={'timer.changeTimeTo'}
								values={{ time: minutes + 1 }}
							/>
						</span>
						<ChevronRight />
					</Button>
				</div>
			)}
		</div>
	);
}

import { createSlice } from '@reduxjs/toolkit';
import { loadStateFromLocalStorage } from '@shared/lib';

//---

export enum TimerCycles {
	IDLE,
	WORK,
	BREAK,
	LONG_BREAK,
}

/* Min timer value in minutes */
export const MIN_TIMER_VALUE = 1;

/* Max timer value in minutes */
export const MAX_TIMER_VALUE = 60;

export const TimerCycleToString: Record<TimerCycles, string> = {
	[TimerCycles.IDLE]: 'Idle',
	[TimerCycles.WORK]: 'Work',
	[TimerCycles.BREAK]: 'Break',
	[TimerCycles.LONG_BREAK]: 'Long Break',
};

export type TimerCyclesWithoutIdle = Exclude<TimerCycles, TimerCycles.IDLE>;

export const initialState = {
	workTimer: 25,
	breakTimer: 5,
	longBreakTimer: 10,
	currentCycle: TimerCycles.IDLE,
};

interface TimerPartOfGlobalState {
	timer: typeof initialState;
}

const timerSlice = createSlice({
	name: 'timer',
	initialState: loadStateFromLocalStorage('timer') || initialState,
	reducers: {
		setWorkTimer(state, action: { payload: number }) {
			state.workTimer = action.payload;
		},
		setBreakTimer(state, action: { payload: number }) {
			state.breakTimer = action.payload;
		},
		setLongBreakTimer(state, action: { payload: number }) {
			state.longBreakTimer = action.payload;
		},
		startWork(state) {
			state.currentCycle = TimerCycles.WORK;
		},
		startBreak(state) {
			state.currentCycle = TimerCycles.BREAK;
		},
		startLongBreak(state) {
			state.currentCycle = TimerCycles.LONG_BREAK;
		},
		setCurrentCycle(state, action: { payload: TimerCycles }) {
			state.currentCycle = action.payload;
		},
		stop(state) {
			state.currentCycle = TimerCycles.IDLE;
		},
	},
});

export const {
	setWorkTimer,
	setBreakTimer,
	setLongBreakTimer,
	startWork,
	startBreak,
	setCurrentCycle,
	startLongBreak,
	stop,
} = timerSlice.actions;

export const selectCurrentCycle = (state: {
	timer: { currentCycle: TimerCycles };
}) => state.timer.currentCycle;

export const selectBreakTimer = (state: TimerPartOfGlobalState) =>
	state.timer.breakTimer;

export const selectLongBreakTimer = (state: TimerPartOfGlobalState) =>
	state.timer.longBreakTimer;

export const selectWorkTimer = (state: TimerPartOfGlobalState) =>
	state.timer.workTimer;

export const timerReducer = timerSlice.reducer;

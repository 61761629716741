import { useEffect, useState } from 'react';

// ---

function isFullscreenEnabled(): boolean {
	return !!document.fullscreenElement;
}

export default function useFullscreen(): [boolean, () => void] {
	const [isFullscreen, setIsFullscreen] = useState(isFullscreenEnabled());

	useEffect(() => {
		const handleFullscreenChange = () => {
			setIsFullscreen(isFullscreenEnabled());
		};

		document.addEventListener('fullscreenchange', handleFullscreenChange);

		return () => {
			document.removeEventListener('fullscreenchange', handleFullscreenChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleFullscreen = () => {
		const canDoFullscreen = !!document.documentElement.requestFullscreen;

		if (!canDoFullscreen) {
			return;
		}

		if (isFullscreen) {
			document.exitFullscreen();
		} else {
			document.documentElement.requestFullscreen();
		}
	};

	return [isFullscreen, toggleFullscreen];
}

import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
	TimerCycles,
	TimerCyclesWithoutIdle,
	TimerCycleToString,
	useTimers,
} from '@entities/timer';
import {
	StartNextCycleModal,
	useTickingTimer,
	getNextCycle,
} from '@features/start-pause-timer';
import { useVibration } from '@features/vibration';

import { SoundPlayer } from '@features/sound';
import { CycleSwitcher } from '@features/select-cycle';
import { Timer3D } from '@features/display-time-and-set-timer';

import { PageAnimationWrapper } from '@shared/ui';
import { selectTodos } from '@entities/todo';
import { useSettings, useThemeAttr } from '@entities/setting';
import { selectHistoryEntries } from '@entities/history';
import { sendTimerFinishedEvent } from '@shared/lib';
import { HeaderLayout } from '../../widgets/header';
import {
	Backgrounds,
	CurrentActivityLabel,
	Drawers,
	MainControls,
	MainPageTimerWrapper,
	WorkCurtainBackground,
	useDrawers,
	DefinedDrawers,
} from './ui';

// ---

export default function MainPage() {
	const { shownDrawer, toggleDrawer } = useDrawers();
	const { cycleToChangeFunction, currentCycle, cycleToTime } = useTimers();
	const { autostart, longBreakEvery, volumeLevel, theme } = useSettings();
	const todos = useSelector(selectTodos);
	const historyEntries = useSelector(selectHistoryEntries);
	const [startNextModalOpen, setStartNextModalOpen] = useState(false);
	const [configuredCycle, setConfiguredCycle] =
		useState<TimerCyclesWithoutIdle>(TimerCycles.WORK);
	const [timerFinished, setTimerFinished] = useState(false);
	const controlsWrapperRef = useRef<HTMLDivElement>(null);

	const { start, stop, tickingTime } = useTickingTimer(
		(cycle: TimerCyclesWithoutIdle) => {
			setConfiguredCycle(cycle);
		},
		(reason) => {
			sendTimerFinishedEvent({
				cycleType: TimerCycleToString[configuredCycle],
				timeSet: cycleToTime[configuredCycle],
				timeStopped:
					reason === 'manual'
						? cycleToTime[configuredCycle] - Number(tickingTime.split(':')[0])
						: Number(tickingTime.split(':')[0]),
				hasAssociatedTask: configuredCycle === TimerCycles.WORK && !!topTodo,
				numberToday: historyEntries.filter(
					// get only today's entries
					(entry) => entry.startedAt > new Date().setHours(0, 0, 0, 0)
				).length,
				theme: theme,
				volumeLevel,
			});

			if (reason !== 'manual') {
				setTimeout(() => {
					if (!autostart) {
						setStartNextModalOpen(true);
					} else {
						const nextCycle = getNextCycle(historyEntries, longBreakEvery);
						start(nextCycle, topTodo?.label);
					}
				}, 100);
			}

			// Set the temporary flag timerFinished state to trigger the sound and vibration
			setTimerFinished(true);
			setTimeout(() => {
				setTimerFinished(false);
			}, 100);
		}
	);

	useThemeAttr(
		controlsWrapperRef.current,
		currentCycle === TimerCycles.WORK ? 'dark' : 'light'
	);

	const handleManualTimerStart = () => {
		const label =
			configuredCycle === TimerCycles.WORK ? topTodo?.label : undefined;
		start(configuredCycle, label);
	};

	const topTodo = todos.find((todo) => !todo.completed);
	const timerIsRunning = currentCycle !== TimerCycles.IDLE;

	const cycleSwitcher = (
		<CycleSwitcher
			currentCycle={configuredCycle}
			onCycleChange={(cycle: TimerCyclesWithoutIdle) =>
				setConfiguredCycle(cycle)
			}
		/>
	);

	useVibration(cycleToTime[configuredCycle], timerFinished);

	return (
		<PageAnimationWrapper layoutId="main" className="flex flex-1 flex-col">
			<Backgrounds selectedCycle={configuredCycle} />

			<HeaderLayout
				currentCycle={currentCycle}
				onToggleSettings={() => toggleDrawer(DefinedDrawers.Settings)}
				onToggleHowToUse={() => toggleDrawer(DefinedDrawers.HowToUse)}
			/>

			<div className="relative flex flex-1 flex-col overflow-hidden">
				<WorkCurtainBackground show={currentCycle === TimerCycles.WORK} />

				<div
					className="relative z-40 flex flex-1 flex-col items-center justify-center transition-opacity touch:landscape:flex-row"
					ref={controlsWrapperRef}
				>
					<div
						className={`${currentCycle === TimerCycles.IDLE ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'} mb-4 w-full duration-300 md:w-[40%] touch:landscape:hidden`}
					>
						{cycleSwitcher}
					</div>
					<MainPageTimerWrapper>
						<Timer3D
							windTime={
								tickingTime ? tickingTime : `${cycleToTime[configuredCycle]}:00`
							}
							onWind={cycleToChangeFunction[configuredCycle]}
							isRunning={tickingTime !== ''}
							disableWinding={timerIsRunning}
						/>
					</MainPageTimerWrapper>
					<div>
						<div className="hidden touch:landscape:block">{cycleSwitcher}</div>

						<div className="mt-2 p-2">
							<CurrentActivityLabel
								selectedCycle={configuredCycle}
								currentTask={topTodo}
							/>
						</div>

						<div>
							<MainControls
								selectedCycle={configuredCycle}
								currentTime={
									timerIsRunning
										? tickingTime
										: `${cycleToTime[configuredCycle]}:00`
								}
								disableTimeEdit={currentCycle !== TimerCycles.IDLE}
								isTodoOpen={shownDrawer === DefinedDrawers.HistoryTodo}
								onStart={handleManualTimerStart}
								onStop={() => stop()}
								onSetTime={(newTime) =>
									cycleToChangeFunction[configuredCycle](newTime)
								}
								onToggleTodoList={() =>
									toggleDrawer(DefinedDrawers.HistoryTodo)
								}
							/>
						</div>
					</div>
				</div>

				<Drawers drawerToShow={shownDrawer} onDrawerClose={toggleDrawer} />

				<StartNextCycleModal
					isOpen={startNextModalOpen}
					onCancel={() => setStartNextModalOpen(false)}
					onStart={() => {
						const nextCycle = getNextCycle(historyEntries, longBreakEvery);
						start(nextCycle, topTodo?.label);
						setStartNextModalOpen(false);
					}}
				/>
			</div>

			<SoundPlayer
				isMuted={false}
				volumeLevel={volumeLevel / 100}
				windTime={cycleToTime[configuredCycle]}
				playEndSound={timerFinished}
				currentCycle={currentCycle}
			/>
		</PageAnimationWrapper>
	);
}

import { Form, NumberField } from 'react-aria-components';
import { I18nMessage } from '@shared/i18n';
import { Done, Cross, Button, Input } from '@shared/ui';
import { MAX_TIMER_VALUE, MIN_TIMER_VALUE } from '@entities/timer';

// ---

interface ManualTimeEditFieldProps {
	initialTime: number | string;
	onChange: (newTime: number) => void;
	onCancel: () => void;
}

export default function ManualTimeEditField({
	initialTime,
	onChange,
	onCancel,
}: ManualTimeEditFieldProps) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				const data = Object.fromEntries(new FormData(event.currentTarget));
				onChange(Number(data.newTime));
			}}
			className="relative h-11 w-full min-w-[calc(6.2ch+6rem)] text-4xl"
		>
			<div className="absolute left-1/2 z-10 translate-x-[-50%]">
				<NumberField
					maxValue={MAX_TIMER_VALUE}
					minValue={MIN_TIMER_VALUE}
					defaultValue={Number(initialTime)}
					name="newTime"
				>
					<div className="flex max-w-[6.2ch] text-4xl">
						<Input
							className="max-h-11 min-h-11 min-w-full !border-baseStrong-900 text-center font-bold dark:!border-baseWeak-900"
							type="number"
							size="lg"
						/>
					</div>
				</NumberField>
			</div>
			<div className="flex h-11 w-full items-center justify-between gap-3 ">
				<Button
					type="nostyles"
					round
					textSizeOverride="text-2xl"
					htmlType="submit"
				>
					<span className="sr-only">
						<I18nMessage id="timer.saveTime" />
					</span>
					<Done />
				</Button>
				<Button
					type="nostyles"
					round
					textSizeOverride="text-2xl"
					onClick={onCancel}
				>
					<span className="sr-only">
						<I18nMessage id="timer.cancelTimeEdit" />
					</span>
					<Cross />
				</Button>
			</div>
		</Form>
	);
}

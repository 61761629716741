import {
	Dialog,
	DialogTrigger,
	ModalOverlay,
	Modal as AriaModal,
} from 'react-aria-components';
import { ReactNode } from 'react';

// ---

interface ModalProps {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
	children: ReactNode;
	className?: string;
	overlayClassName?: string;
}

export default function Modal({
	isOpen,
	onOpenChange,
	className,
	overlayClassName,
	children,
}: ModalProps) {
	return (
		<DialogTrigger>
			<ModalOverlay
				isOpen={isOpen}
				isDismissable
				className={`${overlayClassName} bg-[rgba(45 0 0 / 30%)] data-[entering=true]animate-fade-in data-[exiting=true]animate-fade-out fixed inset-0 z-[100] backdrop-blur-sm`}
				onOpenChange={onOpenChange}
			>
				<AriaModal
					isDismissable
					isOpen={isOpen}
					className={`fixed left-[50%] top-[50%] min-h-40 w-96 max-w-[90%] translate-x-[-50%] translate-y-[-50%] transform outline-none ${className}`}
				>
					<Dialog className="outline-none">
						<div>{children}</div>
					</Dialog>
				</AriaModal>
			</ModalOverlay>
		</DialogTrigger>
	);
}

Modal.defaultProps = {
	className: '',
};
